<template>
    <a-drawer
      :title="title"
      :visible="visible"
      :placement="'top'"
      @close="$emit('close')"
      :height="'100%'"
      :wrapClassName="'addClasses'"
    >
        <div class="content">
           <a-form
                :form="form"
                v-bind="formItemLayout"
            >
                <a-form-item label="上传封面图">
                    <a-upload
                        name="avatar"
                        list-type="picture-card"
                        :show-upload-list="false"
                        :customRequest="customRequest"
                        v-decorator="['fileImg',
                            { rules: [{ required: true, message: '请上传图片' }] },
                        ]"
                    >
                        <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                        <div v-else>
                            <a-icon :type="loading ? 'loading' : 'plus'" style="font-size:30px;color:#dcdcdc"/>
                            <div>上传图片</div>
                        </div>
                    </a-upload>
                    <span>大小不超过10M,格式为jpg</span>
                </a-form-item>
                <a-form-item label="课程名称">
                    <a-input
                        v-decorator="['name', { initialValue: formData.name, rules: [{ required: true, message: '请输入课程包名称' }] }]"
                    />
                </a-form-item>
                <a-form-item label="课程代码">
                    <a-input
                        v-decorator="['code', { initialValue: formData.code, rules: [{ required: true, message: '请输入课程代码' }] }]"
                    />
                </a-form-item>
               <a-form-item label="课程视频">
                   <a-upload
                           name="file"
                           :customRequest="customRequestVideo"
                           class="noBorder"
                   >
                       <a-button>上传视频</a-button>
                   </a-upload>
                   <a-input placeholder="视频解析地址" class="noBorder" v-model="formData.video"/>
                </a-form-item>
                <!--<a-form-item label="分类">
                    <a-select v-if="options" v-decorator="['cate_id', { initialValue: formData.cate_id, rules: [{ required: true, message: '请选择标签' }] }]" @change="changeCate" class="mr10px" placeholder="请选择分类">
                        <a-select-option v-for="item in options" :key="item.id" :value="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="添加科目">
                    <a-select
                        mode="multiple"
                        style="width: 100%"
                        :placeholder="!form.getFieldValue('cate_id')?'请先选择分类': '请选择科目'"
                        :disabled="!form.getFieldValue('cate_id')"
                        v-decorator="['sids', { initialValue: formData.sids, rules: [{ required: true, message: '请选择科目' }] }]"
                    >
                        <a-select-option v-for="i in subjectList" :key="i.id" :value="i.id">
                          {{i.name}}
                        </a-select-option>
                     </a-select>
                </a-form-item>
                <a-form-item label="上传详情页封面图">
                    <a-upload
                        name="avatar"
                        list-type="picture-card"
                        :show-upload-list="false"
                        :customRequest="customRequestDetailBg"
                        v-decorator="['detailfileImg',
                            { rules: [{ required: true, message: '请上传图片' }] },
                        ]"
                    >
                        <img v-if="detail_bg_url" :src="detail_bg_url" alt="avatar" />
                        <div v-else>
                            <a-icon :type="'plus'" style="font-size:30px;color:#dcdcdc"/>
                            <div>上传图片</div>
                        </div>
                    </a-upload>
                    <span>建议尺寸为750*320，大小不超过10M,格式为jpg</span>
                </a-form-item>
                <a-form-item label="价格">
                     <div class="sTable">
                         <div class="th">
                            <div>原价</div>
                            <div>现价</div>
                         </div>
                         <div class="tbody">
                             <div class="taC">
                                <a-form-item>
                                    <a-input type="number" v-decorator="['old_price', { initialValue: formData.old_price, rules: [{ required: true, message: '请填写原价' }] }]" prefix="￥" suffix="元" style="width:160px"/>
                                </a-form-item>
                             </div>
                             <div class="taC">
                                <a-form-item>
                                    <a-input type="number" v-decorator="['now_price', { initialValue: formData.now_price, rules: [{ required: true, message: '请填写现价' }] }]"  prefix="￥" suffix="元" style="width:160px"/>
                                </a-form-item>
                             </div>
                         </div>
                     </div>
                </a-form-item>-->
           </a-form>
            <div style="text-align:right;padding-top:30px;padding-right:20%">
                <a-button class="mr20px" style="width:100px" @click="$emit('close')">取消</a-button>
                <a-button type="primary"  style="width:100px" @click="submit">立即添加</a-button>
            </div>
      </div>
    </a-drawer>
</template>
<script>
import { uploadImg } from '@/libs/api'
import * as http from '@/libs/practice'
import { momentDay,formatDay, momentDays } from '@/libs/moment'
export default {
    name: 'addClasses',
    components:{},
    props: ['visible', 'options', 'data', 'edit'],
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    data(){
        return{
            formItemLayout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 10 },
            },
            imageUrl: '',
            user_type: 1,
            detail_type: 1,
            showP:false,
            loading: false,
            subjectList: [],
            ProfessList:[],
            detailUrl:'',
            title:'添加课程',
            showSchool: false,
            showBpid: false,
            SchoolList: [],
            BpidList: [],
            detailfileImg:[],
            detail_bg_url:'',
            formData:{
                name:'',code:'',title:'',video:'',cate_id:'',user_num:'',
                sids:[],detail_url:'',old_price:'',now_price:'',exam_time:null ,valid_time:'',
                pids: '', school: '', b_pids:''
            }
        }
    },
    watch:{
        visible(val){
            if(!val){
                this.reset()
            }else{
                this.init().then(()=>{
                    if(this.edit){
                        Object.assign(this.formData, this.data);
                        this.title = '编辑课程包'
                        this.imageUrl = this.data.img
                        setTimeout(()=>{
                            this.form.setFieldsValue({'fileImg':[{uid:-1, name: 'image.png',status: 'done', url: this.data.img}]})
                            this.form.setFieldsValue({'detailfileImg':[{uid:2, name: 'imageBg.png',status: 'done', url: this.data.detail_bg_url}]})
                            if(this.detail_type == 2){
                                this.form.setFieldsValue({'detailImg':[{uid:1, name: 'detailimage.png',status: 'done', url: this.data.detail_url}]})
                            }
                        })
                    }else{
                        this.title = '新增课程'
                    }
                })
            }
        }
    },
    methods:{
        reset(){
            this.form.resetFields()
            this.imageUrl = ''
            this.detail_bg_url = ''
            this.user_type = 1
            this.detail_type = 1
            this.subjectList = []
            this.loading = false
            this.detailUrl = ''
            this.showP = false
            this.showSchool = false
            this.formData = {
                name:'',code:'',title:'',video:'',cate_id:'',user_num:'',
                sids:[],detail_url:'',old_price:'',now_price:'',exam_time:null,valid_time:'',
                pids:'', school: '',b_pids:''
            }
        },
        init(){
            return new Promise((resolve,reject) => {
                Promise.all([]).then(res=>{
                    resolve()
                }).catch(reject)
            })
        },
        changeCate(){
            setTimeout(()=>{
                if(this.form.getFieldValue('sids')) this.form.setFieldsValue({'sids': []})
                this.subjectList = this.allSubject.filter(el=>el.cate_id == this.form.getFieldValue('cate_id'))
            })
        },
        customRequestVideo(data){
            let formData = new FormData()
            formData.append('file',data.file)
            uploadImg(formData).then(res=>{
                if(res){
                    this.$message.info('上传完成');
                    this.formData.video = res.fileUrl;
                }
            },error=>{
                console.log(error)
            })
        },
        customRequest(data){
            let formData = new FormData()
            formData.append('file',data.file)
            uploadImg(formData).then(res=>{
                if(res){
                    this.imageUrl=res.fileUrl
                }
            },error=>{
             console.log(error)
            })
        },
        customRequestDetail(data){
            let formData = new FormData()
            formData.append('file',data.file)
            uploadImg(formData).then(res=>{
                if(res){
                    this.detailUrl=res.fileUrl
                }
            },error=>{
             console.log(error)
            })

        },
        customRequestDetailBg(data){
            let formData = new FormData()
            formData.append('file',data.file)
            uploadImg(formData).then(res=>{
                if(res){
                    this.detail_bg_url=res.fileUrl
                }
            },error=>{
             console.log(error)
            })
        },
        submit(){
            this.form.validateFields((err, values) => {
                if(err) return;
                let request = this._.cloneDeep(values)
                console.log(request);
                delete request.fileImg
                request.img = this.imageUrl

                if(this.edit){
                    request.id = this.data.id
                    http.edit_crouse_bag(request).then(res=>{
                        this.$emit('close')
                        this.$emit('update')
                    }).catch(error=>{
                        console.log(error)
                    })
                }else{
                    http.add_crouse_bag(request).then(res=>{
                        this.$emit('close')
                        this.$emit('update')
                    }).catch(error=>{
                        console.log(error)
                    })
                }
            })
        },
        vaildatorPrice(rule, value, callback){
        }
    }
}
</script>
<style lang="less" scoped>
.sTable{
    width: 420px;
    border: 1px solid #eee;
    .th{
        background: #eeeeee;
        height: 32px;
        display: flex;
        align-items: center;
        text-align: center;
        >div{
            flex: 1;
        }
    }
    .tbody{
        padding: 20px 10px;
        display: flex;
        align-items: center;
        >div{
            flex: 1;
        }
    }
}
</style>
